import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
    AssignClientsToUserProperties,
    AssignUsersToClientProperties,
    AvailableHoursForDashboardProperties,
    ClientUserPaginationProperties,
    DismissClientProperties,
    UpdateDefaultBCBAProperties,
    UserClientPaginationProperties,
    UserPatientsProperties,
} from "../../API/ClientAPIHelpers/userClientProperties";
import {
    AuthorizedUsersProperties,
    ShortRBTInfoProperties,
    TherapistProperties,
} from "../../API/ClientAPIHelpers/clientProperties";
import { AvailableUsersDataProperties } from "../../../pages/AdminPanel/Patients/AssignTherapists/tableInfo";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface initialUserClientStateProperties {
    loading: boolean;
    loadingGetUserPatients: boolean;
    loadingGetAvailableBCBAs: boolean;
    loadingGetAvailableRBTs: boolean;
    therapists: Array<TherapistProperties> | null;
    billableUsers: Array<AuthorizedUsersProperties>;
    billableUserId: string | null;
    supervisors: Array<AuthorizedUsersProperties>;
    userPatients: UserPatientsProperties;
    availableUsers: AvailableUsersDataProperties;
    credentialedBCBAs: AvailableUsersDataProperties;
    notCredentialedBCBAs: AvailableUsersDataProperties;
    availableRBTs: AvailableUsersDataProperties;
    userRBTs: Array<ShortRBTInfoProperties>;
    error: ErrorProperties;
    defaultBCBAOptions: Array<TherapistProperties>;
    defaultBCBA: TherapistProperties | null;
    availableHours: Array<AvailableHoursForDashboardProperties> | null,
}

const initialUserClientState: initialUserClientStateProperties = {
    loading: false,
    loadingGetAvailableBCBAs: false,
    loadingGetAvailableRBTs: false,
    loadingGetUserPatients: false,
    billableUsers: [],
    billableUserId: null,
    supervisors: [],
    therapists: null,
    userRBTs: [],
    availableHours: null,
    availableRBTs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    availableUsers: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    credentialedBCBAs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    notCredentialedBCBAs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    userPatients: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    error: { status: 0, title: "" },
    defaultBCBAOptions: [],
    defaultBCBA: null,
}

export const getAvailableHoursForDashboard = createAsyncThunk(
    '/client/insurance-info/available-hours',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getAvailableHoursInfo(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTherapists = createAsyncThunk(
    '/client/therapists',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getTherapists(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<TherapistProperties>;
    }
)

export const getUserRBTs = createAsyncThunk(
    '/client/therapist/rbts',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getRBTs(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ShortRBTInfoProperties>;
    }
)

export const getUserPatients = createAsyncThunk(
    '/client/user/patients',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getPatients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserPatientsProperties;
    }
)

export const getUserPatientsStartSession = createAsyncThunk(
    '/client/user/patients/start-session',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getPatients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserPatientsProperties;
    }
)

export const assignUsersToClient = createAsyncThunk(
    '/client/assign/users',
    async (data: AssignUsersToClientProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.assignUsersToClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const dismissPatient = createAsyncThunk(
    '/client/dismiss',
    async (data: DismissClientProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.dissmissClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const assignClientsToUser = createAsyncThunk(
    '/user/assign/clients',
    async (data: AssignClientsToUserProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.assignClientsToUser(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailableUserPatients = createAsyncThunk(
    '/user/assign/clients',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getAvailablePatients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailablePatientUsers = createAsyncThunk(
    '/user/assign/users',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableTherapists(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getBillableUsers = createAsyncThunk(
    '/client/billable-users/options',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getBillableUsers();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizedUsersProperties>;
    }
)

export const getClientBillableUser = createAsyncThunk(
    '/client/billable-user',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getBillingUserOfClient(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as AuthorizedUsersProperties;
    }
)

export const getSupervisors = createAsyncThunk(
    '/client/supervisors',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getSupervisors();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizedUsersProperties>;
    }
)

export const getAvailableCredentialedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/credentialed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableCredentialedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getLicensedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/licensed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getLicensedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as AvailableUsersDataProperties;
    }
)

export const getAvailableNotCredentialedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/not-credentialed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableNotCredentialedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailablePatientRBTs = createAsyncThunk(
    '/user/assign/rbts',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableRBTs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getDefaultBCBAOptions = createAsyncThunk(
    '/user/default-bcba/options',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getDefaultBCBAOptions(clientId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<TherapistProperties>;
    }
)

export const getDefaultBCBA = createAsyncThunk(
    '/user/default-bcba/',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getDefaultBCBA(clientId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as TherapistProperties;
    }
)

export const updateDefaultBCBA = createAsyncThunk(
    '/user/default-bcba/update',
    async (data: UpdateDefaultBCBAProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.updateDefaultBCBA(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const userClientSlice = createSlice({
    name: 'userClient',
    initialState: initialUserClientState,
    reducers: {
        setBillingUserId(state, action) {
            state.billableUserId = action.payload;
        }
    },
    extraReducers: (builder) => {
        //getAvailableHoursForDashboard
        builder.addCase(getAvailableHoursForDashboard.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableHoursForDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.availableHours = action.payload;
        })
        builder.addCase(getAvailableHoursForDashboard.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getUserRBTs
        builder.addCase(getUserRBTs.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getUserRBTs.fulfilled, (state, action) => {
            state.loading = false;
            state.userRBTs = action.payload;
        })
        builder.addCase(getUserRBTs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //getClientBillableUser
        builder.addCase(getClientBillableUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getClientBillableUser.fulfilled, (state, action) => {
            state.loading = false;
            state.billableUserId = action.payload.id
        })
        builder.addCase(getClientBillableUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDefaultBCBA
        builder.addCase(getDefaultBCBA.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getDefaultBCBA.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultBCBA = action.payload;
        })
        builder.addCase(getDefaultBCBA.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDefaultBCBAOptions
        builder.addCase(getDefaultBCBAOptions.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getDefaultBCBAOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultBCBAOptions = action.payload;
        })
        builder.addCase(getDefaultBCBAOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateDefaultBCBA
        builder.addCase(updateDefaultBCBA.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(updateDefaultBCBA.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(updateDefaultBCBA.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSupervisors
        builder.addCase(getSupervisors.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getSupervisors.fulfilled, (state, action) => {
            state.loading = false;
            state.supervisors = action.payload;
        })
        builder.addCase(getSupervisors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBillableUsers
        builder.addCase(getBillableUsers.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getBillableUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.billableUsers = action.payload;
        })
        builder.addCase(getBillableUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        // //getAvailableUserPatients
        // builder.addCase(getAvailableUserPatients.pending, (state) => {
        //     state.loading = true;
        //     state.error = initialUserClientState.error;
        // })
        // builder.addCase(getAvailableUserPatients.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.availablePatients = action.payload;
        // })
        // builder.addCase(getAvailableUserPatients.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload as ErrorProperties;
        // })
        //getAvailablePatientUsers
        builder.addCase(getAvailablePatientUsers.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailablePatientUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.availableUsers = action.payload;
        })
        builder.addCase(getAvailablePatientUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableCredentialedBCBAs
        builder.addCase(getAvailableCredentialedBCBAs.pending, (state) => {
            state.loadingGetAvailableBCBAs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableCredentialedBCBAs.fulfilled, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.credentialedBCBAs = action.payload;
        })
        builder.addCase(getAvailableCredentialedBCBAs.rejected, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableNotCredentialedBCBAs
        builder.addCase(getAvailableNotCredentialedBCBAs.pending, (state) => {
            state.loadingGetAvailableBCBAs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableNotCredentialedBCBAs.fulfilled, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.notCredentialedBCBAs = action.payload;
        })
        builder.addCase(getAvailableNotCredentialedBCBAs.rejected, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailablePatientRBTs
        builder.addCase(getAvailablePatientRBTs.pending, (state) => {
            state.loadingGetAvailableRBTs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailablePatientRBTs.fulfilled, (state, action) => {
            state.loadingGetAvailableRBTs = false;
            state.availableRBTs = action.payload;
        })
        builder.addCase(getAvailablePatientRBTs.rejected, (state, action) => {
            state.loadingGetAvailableRBTs = false;
            state.error = action.payload as ErrorProperties;
        })
        //get therapists
        builder.addCase(getTherapists.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getTherapists.fulfilled, (state, action) => {
            state.loading = false;
            state.therapists = action.payload;
        })
        builder.addCase(getTherapists.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get users patients
        builder.addCase(getUserPatients.pending, (state) => {
            state.loadingGetUserPatients = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getUserPatients.fulfilled, (state, action) => {
            state.loadingGetUserPatients = false;
            state.userPatients = action.payload;
        })
        builder.addCase(getUserPatients.rejected, (state, action) => {
            state.loadingGetUserPatients = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const { setBillingUserId } = userClientSlice.actions;
export default userClientSlice.reducer;