import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  Text,
} from "../../../../shared/uiComponents";
import { ButtonWrapper, InfoRow } from "../../../AdminPanel/StyledComponents";
import { CustomInput, Picker } from "../../../../shared/uiComponents";
import { white100 } from "../../../../shared/Helpers/colors";
import { useCompareDates } from "../../../../shared/Helpers/hooks";
import { findDuration } from "../../Calendar/Helpers/functions";
import { PERMISSIONS } from "../../../../App/constants";
import { useCheckPermission } from "../../../AdminPanel/Permissions/helpers";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getSessionBehaviors } from "../../../../redux/State/clientSlice/dataCollectionSlice";
import {
  endSession,
  getSessionTargets,
  setEndTime,
  setStartTime,
} from "../../../../redux/State/clientSlice/sessionSlice";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { AdminTypes } from "../../../../components/Action";

export const HeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "&>div": {
    width: "50%",
  },
}));

export const SOAPNoteWrapper = ({
  CreateSoapNote,
  UpdateSoapNote,
}: {
  CreateSoapNote: () => JSX.Element;
  UpdateSoapNote: () => JSX.Element;
}) => {
  const [params] = useSearchParams();
  const reportId = params.get("reportId");
  return !!reportId ? <UpdateSoapNote /> : <CreateSoapNote />;
};

export const SessionTargetsInfo = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const sessionTargets = useSelector((state) => state.session.sessionTargets);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSessionTargets(sessionId));
  }, [params, dispatch]);

  return (
    <>
      <Text
        title="Session targets:"
        size="smallBold"
        className="marginBottom16"
      />

      {sessionTargets.map((target, index) => (
        <div
          key={index}
          className="marginBottom16"
          style={{
            border: "1px solid black",
            borderRadius: "12px",
            padding: "8px",
            width: "100%",
          }}
        >
          <InfoRow className="marginBottom8">
            <Text title={"Skill area name:"} size="tinyBold" />
            <Text title={target.skillArea.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Program name:"} size="tinyBold" />
            <Text title={target.program.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Target name:"} size="tinyBold" />
            <Text title={target.target.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Independent:"} size="tinyBold" />
            <Text title={`${target.correctPercentage}%`} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Prompted:"} size="tinyBold" />
            <Text
              title={`${100 - target.correctPercentage}%`}
              size="tinyBold"
            />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Trials:"} size="tinyBold" />
            <Text title={target.trials} size="tinyBold" />
          </InfoRow>
        </div>
      ))}
    </>
  );
};

export const SessionBehaviorsInfo = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const sessionBehaviors = useSelector(
    (state) => state.dataCollection.sessionBehaviors
  );

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSessionBehaviors(sessionId));
  }, [params, dispatch]);

  return (
    <>
      <Text title="Behaviors:" size="smallBold" className="marginBottom16" />

      {sessionBehaviors.map((behavior, index) => (
        <div
          key={index}
          className="marginBottom16"
          style={{
            border: "1px solid black",
            borderRadius: "12px",
            padding: "8px",
            width: "100%",
          }}
        >
          <InfoRow className="marginBottom8">
            <Text title={"Behavior name:"} size="tinyBold" />
            <Text title={behavior.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Category name:"} size="tinyBold" />
            <Text title={behavior.category.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Recpording type:"} size="tinyBold" />
            <Text title={behavior.recordingType.text} size="tinyBold" />
          </InfoRow>
          {}
          {behavior.count !== null && (
            <InfoRow className="marginBottom8">
              <Text title={"Count:"} size="tinyBold" />
              <Text title={`${behavior.count}`} size="tinyBold" />
            </InfoRow>
          )}
          {behavior.duration !== null && (
            <InfoRow className="marginBottom8">
              <Text title={"Total duration:"} size="tinyBold" />
              <Text title={`${behavior.duration} seconds`} size="tinyBold" />
            </InfoRow>
          )}
        </div>
      ))}
    </>
  );
};

const TimeWrapper = styled("div")(() => ({
  display: "flex",
  gap: "32px",
  width: "100%",
  flexWrap: "wrap",
}));

export const TimeInfo = ({ disabled }: { disabled?: boolean }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const { date, startTime, endTime } = useSelector(
    (state) => state.session.sessionInfo
  );
  const noteDate = !!date ? date.split("T")[0] : "";

  const { hasError, message } = useCompareDates(
    dayjs(startTime),
    dayjs(endTime)
  );

  const startTimeHandler = (value: Dayjs | null) => {
    if (!value) return;
    const startTime = value.format().slice(10, 19);
    dispatch(setStartTime(`${noteDate}${startTime}`));
  };

  const endTimeHandler = (value: Dayjs | null) => {
    if (!value) return;
    const endTime = value.format().slice(10, 19);
    dispatch(setEndTime(`${noteDate}${endTime}`));
  };

  return !!startTime && !!endTime ? (
    <TimeWrapper className="marginBottom16">
      <Picker.CustomTime
        label="Start time"
        onChange={startTimeHandler}
        value={dayjs(startTime)}
        disabled={disabled}
      />
      <Picker.CustomTime
        label="End time"
        onChange={endTimeHandler}
        value={dayjs(endTime)}
        error={hasError}
        errorMessage={message}
        disabled={disabled}
      />
      <CustomInput
        label="Duration:"
        value={findDuration(startTime, endTime)}
        setValue={() => {}}
        disabled={true}
      />
    </TimeWrapper>
  ) : null;
};

export const isActionAllowed = (status: number) => {
  if (
    status === SoapNotesStatuses.submitted ||
    status === SoapNotesStatuses.expired48 ||
    status === SoapNotesStatuses.expiredWeek ||
    status === SoapNotesStatuses.pendingBT
  ) {
    return false;
  }

  return true;
};

export const SoapNoteButtons = ({
  status,
  save,
  submit,
}: {
  status: number;
  save: () => void;
  submit: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const CREATE = useCheckPermission(PERMISSIONS.SOAPNOTE.CREATE);
  const EDIT = useCheckPermission(PERMISSIONS.SOAPNOTE.EDIT);

  const [open, setOpen] = useState<boolean>(false);

  const soapNoteLoading = useSelector((state) => state.soapNote.loading);
  const sessionLoading = useSelector((state) => state.session.loading);

  if (!isActionAllowed(status)) return null;

  const submitHandler = () => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(endSession(sessionId))
      .then(unwrapResult)
      .then(() => toast("Session ended"))
      .catch(() => {})
      .finally(() => submit());
  };

  return (
    <ButtonWrapper>
      {EDIT.permissionGranted && (
        <CustomButton
          title="Save as draft"
          onClick={save}
          className="marginTop8"
          secondaryButton
          loading={soapNoteLoading || sessionLoading}
        />
      )}
      {CREATE.permissionGranted && (
        <CustomButton
          title="Submit"
          className="marginTop8"
          onClick={() => setOpen(true)}
          loading={soapNoteLoading}
        />
      )}
      <CustomAlert
        open={open}
        title="Attention"
        onClose={() => setOpen(false)}
        Content={() => (
          <div style={{ textAlign: "center" }}>
            <Text
              title={
                "The related session will be automatically concluded. Please ensure all necessary data has been gathered."
              }
              className="padding16"
              size="largeBold"
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpen(false)}
              className="marginBottom16"
            />
            <CustomButton
              title="Submit"
              onClick={submitHandler}
              loading={soapNoteLoading || sessionLoading}
            />
          </div>
        )}
      />
    </ButtonWrapper>
  );
};

export const Loading = () => (
  <>
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: white100,
        opacity: 0.85,
        width: "100%",
        height: "100%",
        zIndex: 1000,
      }}
    />
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <Text title={"Loading..."} size="smallBold" />
    </div>
  </>
);

export const SOAP_NOTES = "unsaved-soap-notes";

export const SaveSoapNote = (data: any) => {
  const notes = window.localStorage.getItem(SOAP_NOTES);
  const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];

  if (!soapNotes.find((x) => x.sessionId === data.sessionId)) {
    soapNotes.push(data);
    window.localStorage.setItem(SOAP_NOTES, JSON.stringify(soapNotes));
    return;
  }
  const updatedSoapNotes = soapNotes.map((soapNote) => {
    if (soapNote.sessionId === data.sessionId) {
      return data;
    }
    return soapNote;
  });

  window.localStorage.setItem(SOAP_NOTES, JSON.stringify(updatedSoapNotes));
};

export const removeSavedSoapNoteData = (id: string) => {
  const notes = window.localStorage.getItem(SOAP_NOTES);
  const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
  if (!soapNotes || !soapNotes.length) return;

  const updatedSoapNotes = soapNotes.filter((x) => x.sessionId !== id);
  window.localStorage.setItem(SOAP_NOTES, JSON.stringify(updatedSoapNotes));
};

export const hasAnyData = (data: object) =>
  Object.values(data).some((x) => {
    if (typeof x === "string" || Array.isArray(x)) return !!x.length;
    return false;
  });
export const ForcedOperation = ({ submit }: { submit: () => void }) => {
  const [errorList, setErrorList] = useState<Array<string>>([]);
  const [params, setParams] = useSearchParams();
  const error = useSelector((state) => state.soapNote.error);
  const role = useSelector((state) => state.account.role);

  const onClose = () => {
    params.delete("forcedOperation");
    setParams(params);
    setErrorList([]);
  };

  useEffect(() => {
    if (!error.errors) return;
    const errorList = Object.values(error.errors) as Array<string>;
    setErrorList(errorList);
  }, [error]);

  return (
    <CustomAlert
      open={!!errorList.length && AdminTypes.administrator === role?.section.id}
      onClose={onClose}
      title="Are you sure?"
      Content={() => (
        <div>
          {errorList.map((x) => (
            <Text title={x} size="smallBold" className="marginBottom8" />
          ))}
          <CustomCheckBox
            item={{
              id: "0",
              label: "Ignore required fields",
              checked: params.get("forcedOperation") === "true" ? true : false,
            }}
            onChange={(event) => {
              const { checked } = event.target;
              params.set("forcedOperation", `${checked}`);
              setParams(params);
            }}
          />
          <ButtonWrapper>
            <CustomButton title="Cancel" onClick={onClose} />
            <CustomButton
              title="Confirm"
              secondaryButton
              onClick={submit}
              disabled={params.get("forcedOperation") === "true" ? false : true}
            />
          </ButtonWrapper>
        </div>
      )}
    />
  );
};
